@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.product_list {}

.product_list__container {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.product_list__body {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	margin: 0 -2rem -2rem 0;
}

.product_list__item {
	padding: 0 2rem 2rem 0;

	display: flex;
	flex-direction: column;

	@include bp($point_9, min) {
		.product_list--search_mod & {
			width: 20%;
		}

		&:not(.product_list--search_mod &) {
			width: 25%;
		}
	}

	@include bp($point_9 - 1, $point_4) {
		width: 33.333%;
	}

	@include bp($point_4 - 1, $point_6) {
		width: 50%;
	}

	@include bp($point_6 - 1) {
		width: 100%;
	}
}

.product_list__description {
	color: $text_color;
	line-height: 1.5;
	text-align: justify;

	@include bp($point_2, min) {
		font-size: 1.6rem;
	}

	@include bp($point_3) {
		font-size: 1.4rem;
	}
}

.product_list__error {
	padding: 2rem;
	text-align: center;

	@include bp($point_2, min) {
		font-size: 1.8rem;
	}

	@include bp($point_3) {
		font-size: 1.6rem;
	}
}