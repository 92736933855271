@import "../../globalStyles/variables";

.cart_button {
	position: relative;

	display: flex;
	justify-content: flex-start;
	align-items: flex-end;

	width: 3em;
	height: 2.4em;

	&.cart_button--current_state .cart_button__icon {
		color: $primary_color;
	}

	@media (any-hover: hover) {
		&:hover .cart_button__icon {
			color: $primary_color;
		}
	}
}

.cart_button__counter {
	position: absolute;
	top: -.4em;
	right: -.2em;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 1.4em;
	height: 1.4em;
	border-radius: 50%;

	background: $primary_color;
	font-family: $font_text;
	color: $white;
	font-size: 1em;
}

.cart_button__icon {
	font-size: 2em;
	color: $text_color;

	transition: color .3s ease;
}