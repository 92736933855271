@import "./variables";
@import "./mixins";

@mixin search_input {
	display: block;
	width: 100%;

	height: $input_height;
	padding: $input_offset;
	font-size: $input_font_size;
	font-family: $font_text;

	color: $text_color;
	box-shadow: inset 0 0 0 1.1px $input_border_color;
	background-color: transparent;
	border: none;
	background-clip: padding-box;
	box-sizing: border-box;

	transition: box-shadow .3s ease;
	will-change: box-shadow;

	&:hover,
	&:focus {
		box-shadow: inset 0 0 0 2px $input_border_color;
	}

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-moz-placeholder,
	&:-moz-placeholder {
		color: $input_placeholder_color;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: $input_placeholder_color;
	}

	/* clears the 'X' from IE */
	&::-ms-clear,
	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}

	/* clears the 'X' from Chrome */
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

@mixin range_input {
	display: block;
	width: 100%;

	height: $input_height;
	padding: 0 1rem;
	font-size: $input_font_size;
	font-family: $font_text;

	color: $text_color;
	box-shadow: inset 0 0 0 1.1px $input_border_color;
	background-color: $bg_color;
	border: none;
	background-clip: padding-box;
	-moz-appearance: textfield;

	transition: box-shadow .3s ease;
	will-change: box-shadow;

	&:hover,
	&:focus {
		box-shadow: inset 0 0 0 2px $input_border_color;
	}

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-moz-placeholder,
	&:-moz-placeholder {
		color: $input_placeholder_color;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

@mixin default_input {
	display: block;
	width: 100%;

	height: $input_height;
	padding: 0 1rem;
	font-size: $input_font_size;
	font-family: $font_text;
	border-radius: 2px;
	color: $text_color;
	box-shadow: inset 0 0 0 1.1px $input_border_color;
	background-color: transparent;
	border: none;
	background-clip: padding-box;
	-moz-appearance: textfield;
	overflow: hidden;

	transition: box-shadow .3s ease;
	will-change: box-shadow;

	&.error {
		box-shadow: inset 0 0 0 1.1px $primary_color;
	}

	&:hover,
	&:focus {
		&:not(.error) {
			box-shadow: inset 0 0 0 2px $input_border_color;
		}
	}

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-moz-placeholder,
	&:-moz-placeholder {
		color: $input_placeholder_color;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&::-webkit-autofill,
	&::-webkit-contacts-auto-fill-button,
	&::-webkit-credentials-auto-fill-button,
	&::-webkit-contacts-auto-fill-button {
		display: none !important;
		visibility: hidden;
		position: absolute;
		pointer-events: none;
		right: 0;
	}
}

@mixin default_textarea {
	position: relative;
	display: block;
	width: 100%;

	min-height: 14rem;
	padding: 1rem;
	font-size: $input_font_size;
	font-family: $font_text;
	border-radius: 2px;
	background-color: transparent;

	color: $text_color;
	box-shadow: inset 0 0 0 1.1px $input_border_color;
	border: none;
	background-clip: padding-box;
	-moz-appearance: textfield;

	transition: box-shadow .3s ease;
	will-change: box-shadow;

	&:hover,
	&:focus {
		box-shadow: inset 0 0 0 2px $input_border_color;
	}

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-moz-placeholder,
	&:-moz-placeholder {
		color: $input_placeholder_color;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}