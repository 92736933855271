@import "../../globalStyles/variables";
@import "../../globalStyles/formMixins";
@import "../../globalStyles/buttonsMixins";
@import "../../globalStyles/typographyMixins";

.form_layout {
	&.form_layout--disabled_mod {
		pointer-events: none;
	}

	h3 {
		font-size: 2rem;
		text-align: center;

		max-width: 48rem;
		margin: 0 auto 2rem;
	}

	p {
		font-size: 1.4rem;
		text-align: center;
		color: $text_color;
		opacity: 0.8;

		max-width: 40rem;
		margin: 0 auto 2rem;
	}
}

.form_layout__body {
	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}

.form_layout__row {
	display: flex;
	flex-wrap: wrap;

	margin: 0 -2rem -2rem 0;
}

.form_layout__item {
	position: relative;
	padding: 0 2rem 2rem 0;

	&.form_layout__item--full_mod {
		width: 100%;
	}

	&.form_layout__item--thirty_mod {
		@include bp($point_5, min) {
			width: 30%;
		}

		@include bp($point_5 - 1) {
			width: 34%;
		}
	}

	&.form_layout__item--seventy_mod {
		@include bp($point_5, min) {
			width: 70%;
		}

		@include bp($point_5 - 1) {
			width: 66%;
		}
	}


	&.form_layout__item--fifty_mod {
		@include bp($point_5, min) {
			width: 50%;
		}

		@include bp($point_5 - 1) {
			width: 100%;
		}
	}

	&:not(.form_layout__item--select_mod) {
		input {
			@include default_input;
		}
	}
}

.form_layout__footer {
	display: flex;
	justify-content: center;
	gap: 3rem;
}

.form_layout__button {
	min-width: 14rem;

	@include btn_style_secondary;
	@include btn_size_base;
}

.form_layout__resend_button {
	min-width: 12rem;

	@include btn_style_primary;
	@include btn_size_base;

	&.form_layout__resend_button--disabled_state {
		opacity: 0.6;
		pointer-events: none;
		border-color: gray;
	}
}