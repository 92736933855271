@mixin st_btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	text-align: center;
	white-space: nowrap;
	font-family: $font_text;

	cursor: pointer;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
		}
	}
}

//color mixins:
@mixin btn_style_primary {
	position: relative;

	background: transparent;
	border: .3rem solid $primary_color;
	color: $text_color;
	font-weight: 700;

	span {
		position: relative;
		z-index: 2;
	}

	&::before {
		position: absolute;
		top: -.1rem;
		left: -.1rem;
		z-index: 1;
		width: calc(100% + .2rem);
		height: calc(100% + .2rem);

		border-radius: inherit;
		background-color: $primary_color;
		overflow: hidden;

		pointer-events: none;
		transform: scaleX(0);
		content: '';
		transition: transform .3s ease-out;
		will-change: transform;
	}

	@media (any-hover: hover) {
		&:hover {
			&::before {
				transform: scaleX(1);
			}
		}
	}

	@include st_btn;
}

@mixin btn_style_secondary {
	background: $primary_color;
	color: $white;
	font-weight: 700;

	transition: opacity 0.3s ease 0s;

	@media (any-hover: hover) {
		&:hover {
			opacity: 0.8;
		}
	}

	@include st_btn;
}

@mixin btn_style_disabled {
	background: rgba($black, .6);
	color: $primary_color;
	font-weight: 700;

	transition: opacity 0.3s ease 0s;

	span {
		transition: color 0.3s ease 0s;
		will-change: color;

		color: rgba($white, .8);
	}

	@media (any-hover: hover) {
		&:hover {
			opacity: 0.8;
		}
	}

	@include st_btn;
}

@mixin btn_style_disabled_secondary {
	background: rgba($black, .6);
	color: rgba($white, .8);
	font-weight: 700;
	font-size: 1.8rem;

	transition: opacity 0.3s ease 0s;

	@media (any-hover: hover) {
		&:hover {
			opacity: 0.8;
		}
	}

	@include st_btn;
}

@mixin btn_style_not_availabel {
	color: $text_color;
	border: .2rem solid $text_color;
	opacity: 0.5;


	@include st_btn;
}

@mixin btn_style_notify {
	color: $text_color;
	border: .2rem solid $text_color;
	opacity: 0.8;
	background-color: rgba($primary_color, .4);


	@include st_btn;
}

@mixin btn_style_app {
	position: relative;
	overflow: hidden;
	isolation: isolate;

	background: $black;
	color: $white;

	span {
		position: relative;
		z-index: 2;
	}

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		width: 100%;
		height: 100%;

		background-color: $primary_color;
		pointer-events: none;
		opacity: 0;

		content: '';
		transform: translateX(-100%);
		transition: transform .3s ease, opacity .3s ease-out;
		will-change: transform, opacity;
	}

	@media (any-hover: hover) {
		&:hover {
			&::before {
				transform: translateX(0);
				opacity: 1;
			}
		}
	}

	@include st_btn;
}

@mixin btn_style_buy {
	position: relative;
	overflow: hidden;

	background: $bg_color;
	color: $primary_color;
	box-shadow: rgba($black, 0.15) .2rem .2rem .26rem;

	transition: opacity 0.3s ease 0s;

	@media (any-hover: hover) {
		&:hover {
			opacity: .8;
		}
	}

	@include st_btn;
}

//size mixins:
@mixin btn_size_small {
	height: 4rem;
	padding: 0 2rem;
	font-size: 1.6rem;

	border-radius: .6rem;
}

@mixin btn_size_normal {
	height: 3.8rem;
	padding: 0 .8rem;
	font-size: 1.6rem;

	border-radius: .6rem;
}

@mixin btn_size_base {
	height: 4rem;
	padding: 0 2rem;
	font-size: 1.6rem;

	border-radius: .4rem;
}

@mixin btn_size_lg {
	height: 5rem;
	min-width: 16rem;
	padding: 0 2rem;
	font-size: 2rem;

	border-radius: .6rem;
}


@mixin btn_size_big {
	height: 6rem;
	padding: 0 2rem;
	font-size: 2rem;

	border-radius: .6rem;
}

@mixin btn_size_square {
	height: 3.8rem;
	padding: 0 1rem;
	font-size: 1.8rem;

	border-radius: .6rem;
}