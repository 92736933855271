@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.catalog {
	position: relative;
	z-index: 1;

	width: 28rem;
	min-height: 100%;

	background-color: $bg_dedicated_color;
	transition: opacity .3s ease;

	&.catalog--submenu_mod {
		position: absolute;
		z-index: 3;
		top: 0;
		transform: translateX(calc(100% - .4rem));

		border-left: .1rem solid $text_color;

		&:not(.catalog--open_state) {
			opacity: 0;
			pointer-events: none;
		}

		&.catalog--open_state {
			opacity: 1;
			pointer-events: initial;
		}

		&::before {
			content: '';
			position: absolute;
			top: -.1rem;
			left: 0;

			width: 100%;
			height: 0.1rem;

			background-color: $primary_color;
		}
	}
}

.catalog__list {
	$height_limiter: calc(100vh - var(--height-header));

	padding: 2rem 0;
	max-height: $height_limiter;
	overflow-x: hidden;
	overflow-y: auto;

	@include redesign_scrollbar;
}