// fonts
$font_path: '../../assets/fonts/';

$font_heading: 'Conthrax', sans-serif;
$font_text: 'Montserrat', sans-serif;

$font_size_base: 1.6rem;

// layout
$page_width: 144rem;

// media
$point_0: 1700px;
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
$point_9: 1400px;

// colors
// look index.sccs to change theme colors
$bg_color: var(--bg);
$bg_dedicated_color: var(--bg-dedicated);
$bg_menu_color: var(--bg-menu);
$invert_color: var(--invert);
$text_color: var(--text);
$placeholder_color: grey;

// independent colors
$primary_color: #F44036;
$white: #fff;
$black: #000;
$green: #028502;
$purple: #5551C4;
$yellow: #ffff00;
$orange: #F2AA3C;
$blue: #5FA2F8;
$grey: #D2D2D2;

// forms
$input_height: 4rem;
$input_offset: 0 2rem;
$input_font_size: 1.6rem;

$input_border_color: $text_color;
$input_placeholder_color: $placeholder_color;