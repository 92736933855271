$offset: 1rem;

.products_slider {
	margin: 0 calc($offset * -1);
}

.products_slider__body {
	position: relative;
	padding: 1.4rem $offset;

}

.products_slider__slide {
	display: flex;
	flex-direction: column;

	&.swiper-slide {
		height: auto;
	}
}

.products_slider__arrow {
	position: absolute;
	top: 28%;
	z-index: 5;


	&.products_slider__arrow--next_mod {
		right: $offset;
	}

	&.products_slider__arrow--prev_mod {
		left: $offset;
		transform: rotate(-180deg);
	}
}