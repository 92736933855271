@import "../../../globalStyles/variables";
@import "../../../globalStyles/mixins";
@import "../../../globalStyles/typographyMixins";

.single_news_content {}

.single_news_content__row {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.single_news_content__column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&.single_news_content__column--half_mod {
		@include bp($point_4, min) {
			width: 50%;
		}

		@include bp($point_4 - 1) {
			width: 100%;
		}
	}

	&:not(.single_news_content__column--half_mod) {
		width: 100%;
	}

	@include bp($point_4 - 1) {
		&:not(:last-child) {
			margin-bottom: 4rem;
		}
	}
}

.single_news_content__text {
	font-family: $font_text;

	h1 {
		@include h2;

		display: inline-block;

		padding-bottom: 0.4rem;
		border-bottom: .2rem solid $primary_color;


		&:not(:last-child) {
			margin-bottom: 2.6rem;
		}
	}

	h2 {
		@include h2;

		&:not(:last-child) {
			margin-bottom: 1.8rem;
		}
	}

	h3,
	h4,
	h5 {
		font-family: $font_heading;
		font-size: 1.6rem;

		&:not(:last-child) {
			margin-bottom: 1.4rem;
		}
	}

	p:not(:last-child) {
		margin-bottom: 2rem;
	}

	a {
		color: $primary_color;

		transition: opacity 0.3s ease 0s;

		@media (any-hover: hover) {
			&:hover {
				text-decoration: none;
				opacity: 0.8;
			}
		}
	}

	strong {
		font-weight: 700;
	}

	ul,
	ol {
		li {
			&:not(:last-child) {
				margin-bottom: .6rem;
			}
		}

		li:before {
			display: inline-block;
		}
	}

	ul {
		li::before {
			content: '';
			margin-bottom: .2rem;
			margin-right: .8rem;

			width: 0.4rem;
			height: 0.4rem;
			border-radius: 50%;

			background-color: $text_color;
		}
	}

	ol {
		counter-reset: numericList;

		li::before {
			counter-increment: numericList;
			content: counter(numericList) '.';

			margin-right: .8rem;
		}
	}

	ul,
	ol,
	p {
		font-size: 1.8rem;

		&:not(:last-child) {
			margin-bottom: 2.4rem;
		}
	}
}

.single_news_content__image {
	display: block;
	width: 100%;
	height: 100%;

	img {
		object-position: top;
	}
}

.single_news_content__info {
	@include bp($point_4, min) {
		padding: 4rem;
	}
}