@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";

.category_card {
	display: flex;
	flex-direction: column;
	height: 100%;

	background-color: $bg_dedicated_color;
	border-radius: .8rem;
	text-align: center;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;

			.category_card__image {
				transform: scale(1.1);
			}

			.category_card__name {
				color: $primary_color
			}
		}
	}
}

.category_card__wrap {
	display: block;
	width: 100%;
	overflow: hidden;
	border-radius: .8rem .8rem 0 0;

	&:not(:last-child) {
		margin-bottom: .8rem;
	}

	&:not(.category_card__wrap--big_mod) {
		padding: 0 4rem;
		background-color: #fff;
	}
}

.category_card__image {
	position: relative;
	width: 100%;

	transition: transform 0.3s ease 0s;
	will-change: transform;

	.category_card__wrap--big_mod & {
		padding-bottom: 44%;
	}

	&:not(.category_card__wrap--big_mod &) {
		padding-bottom: 100%;
		border-radius: .8rem;
		overflow: hidden;
	}

	picture {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.category_card__name {
	flex: 1 1 auto;
	padding: 0 1.2rem 1.2rem;

	font-weight: 700;
	transition: color 0.3s ease 0s;

	@include h5;
}