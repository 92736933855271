@import "globalStyles/reset";
@import "globalStyles/fonts";
// helpers
@import "globalStyles/variables";
@import "globalStyles/mixins";

:root {
	--gl-indent: 1.6rem;
	--scrollbar-width: 19px;

	@include bp($point_2, min) {
		--height-header: 13.6rem;
	}

	@include bp($point_3) {
		--height-header: 6rem;
	}
}

html {
	height: 100%;

	@include bp($point_0, min) {
		font: 400 calc(100vw / 1600 * 10)/1.33 $font_text;
	}

	@include bp($point_0 - 1, $point_7) {
		font: 400 10px/1.33 $font_text;
	}

	@include bp($point_1 - 1, $point_2) {
		font: 400 calc(100vw / 1200 * 10)/1.33 $font_text;
	}

	@include bp($point_6 - 1) {
		font: 400 calc(100vw / 480 * 10)/1.33 $font_text;
	}
}

body {
	position: relative;

	display: block;
	height: 100%;

	font-size: $font_size_base;
	color: $text_color;

	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: $bg_color;
	transition: background .3s ease;

	&.body--scroll_hide_state {
		overflow: hidden;
	}

	&.body--open_menu_state {
		overflow: hidden;
	}

	.html--lock_padding_state & {
		padding-right: var(--scrollbar-width);
	}

	// theme colors
	&[theme="light-theme"] {
		--bg: #FFFFFF;
		--bg-dedicated: #ededed;
		--bg-menu: #ededed;
		--text: #2F3137;
		--invert: #000;
	}

	&[theme="dark-theme"] {
		--bg: #2F3137;
		--bg-dedicated: #494949;
		--bg-menu: #19212b;
		--text: #FFFFFF;
		--invert: #fff;
	}
}

.wrapper {
	position: relative;

	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: auto !important;

	overflow: hidden;

	transition: filter 0.3s ease 0s;

	.body--wrapper_blurred_state &,
	.body--blurred_state & {
		@include blur(1rem);
	}
}

.base {
	width: 100%;
	position: relative;
	padding: var(--height-header) 0 0;

	flex: 1 0 auto;
}

a {
	font-weight: 400;
	text-decoration: none;
	color: $text_color;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: underline;
		}
	}
}


button {
	font-family: $font_text;
	cursor: pointer;
}

.font_icon {
	line-height: 0;
}

.swiper {
	max-width: 100%;
}

.swiper-pagination {
	display: flex;
	align-items: center;
	justify-content: center;

	.swiper-pagination-bullet {
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		margin: 0 .6rem;

		background: #494949;
		opacity: .4;
		transform: scale(.8);
		transition: opacity .3s ease, background .3s ease, transform .3s ease;

		cursor: pointer;

		&.swiper-pagination-bullet-active {
			background: $primary_color;
			opacity: 1;
			transform: scale(1);
		}
	}
}

.rnc__base {
	top: 0;
	left: 0;
}