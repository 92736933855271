@use "sass:math";

// images mixins
@mixin cover_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

@mixin contain_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
}

// other
@mixin redesign_scrollbar {
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: .2rem;
		height: .4rem;
		background: transparent;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		width: .4rem;
		border-radius: 1rem;
		background: $text_color;
	}

	&::-webkit-scrollbar-corner {
		background: transparent;
	}
}

@mixin hide_scrollbar {
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
}

@mixin showHorizontalScroll($width) {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: -4rem;
		z-index: 1;

		width: calc(4rem + $width);
		height: 100%;
		background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, $bg_color 50%);
		opacity: 0.96;

		pointer-events: none;
	}
}

@mixin text_overflow($lines: 2) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin section_padding {
	@include bp($point_2, min) {
		padding: 8rem var(--gl-indent);
	}

	@include bp($point_3) {
		padding: 4rem var(--gl-indent);
	}
}

@mixin bp($width, $value: max) {
	@if $value ==min {
		@media screen and (min-width: $width) {
			@content;
		}
	}

	@else if $value ==max {
		@media screen and (max-width: $width) {
			@content;
		}
	}

	@else {
		@media (max-width: $width) and (min-width: $value) {
			@content;
		}
	}
}

@mixin font_face($file_name, $font_name: $file_name, $path: $font_path, $weight: normal, $style: normal) {
	@font-face {
		font-family: quote($font_name);
		src: url($path + $file-name + ".woff2") format("woff2"), url($path + $file-name + ".woff") format("woff");
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}

@mixin blur($radius) {
	-webkit-filter: blur($radius);
	-moz-filter: blur($radius);
	-o-filter: blur($radius);
	-ms-filter: blur($radius);
	filter: blur($radius)
}