@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 2.6rem;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}


.pagination__arrow {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 2.4rem;
	height: 2.4rem;
	border-radius: 50%;
	flex-shrink: 0;

	background-color: $bg_dedicated_color;
	color: $text_color;
	font-size: 1.6rem;

	transition: background 0.3s ease 0s;

	&.pagination__arrow--prev_mod {
		transform: rotate(90deg);

		span {
			transform: translateY(.3rem);
		}
	}

	&.pagination__arrow--next_mod {
		transform: rotate(-90deg);

		span {
			transform: translateY(.3rem);
		}
	}

	&.pagination__arrow--disabled_mod {
		pointer-events: none;
		opacity: 0.6;
	}

	@media (any-hover: hover) {
		&:hover {
			background: $primary_color;
		}
	}
}

.pagination__list {
	display: flex;
	align-items: center;

	gap: 1.8rem;
}

.pagination__item {
	flex-shrink: 0;
}

.pagination__button {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 3rem;
	height: 3rem;
	border-radius: 50%;

	color: $text_color;
	background-color: $bg_dedicated_color;
	border: .1rem solid transparent;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1;

	transition: background 0.3s ease 0s, color 0.3s ease 0s, border-color 0.3s ease 0s;

	&.pagination__button--active_state {
		background-color: $primary_color;
		pointer-events: none;
	}

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
			background-color: transparent;
			color: $primary_color;
			border-color: $primary_color;
		}
	}
}

.pagination__dotts {
	font-size: 2rem;
	letter-spacing: 0.1em;
	color: $text_color;
}