@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";

.promotion_card {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	box-shadow: rgba($black, 0.2) 0 .2rem 1rem 0, rgba($black, 0.1) 0 .2rem .4rem 0, rgba($white, 0.05) 0 0 0 .1rem inset;
	border-radius: .6rem;
	overflow: hidden;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
		}
	}
}

.promotion_card__wrap {
	position: relative;
	padding-bottom: 48%;
	overflow: hidden;
}

.promotion_card__image {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	transition: transform 0.3s ease 0s;
	will-change: transform;

	@media (any-hover: hover) {
		.promotion_card:hover & {
			transform: scale(1.1);
		}
	}
}

.promotion_card__content {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	padding: 2rem;
	background-color: $bg_dedicated_color;
}
.promotion_card__wrap1{
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-start;
}

.promotion_card__title {
	flex: 1;
	transition: color 0.3s ease 0s;
	margin-right: 1rem;

	&:not(:last-child) {
		margin-bottom: 1.4rem;
	}

	@include h5;
	@include text_overflow(3);

	@media (any-hover: hover) {
		.promotion_card:hover & {
			color: $primary_color;
		}
	}
}

.promotion_card__separator {
	width: 2px;
	height: 4rem;
	background-color: $black;
	margin-right: 1rem;
}

.promotion_card__date {
	font-size: 1.2rem;
	color: $text_color;
	opacity: 0.8;
	margin-right: auto;
	width: 90px;
	text-align: center;
	font-family: $font_heading;

	.promotion_card__days {
		font-size: 1.5rem;
		font-weight: 900;
		color: red;
		margin-bottom: 0.2rem;

		.promotion_days_end {
			font-family: $font_text;
			font-size: 1.5rem;
			color: $black;
		}

		.promotion_card__timeInDay {
			font-size: 14px;
			font-weight: 800;
			line-height: 21.94px;
		}

		.promotion_card__time {
			font-size: 14px;
			font-weight: 800;
			line-height: 21.94px;
		}

		.promotion_card__time {
			display: inline-flex;
			font-size: 14px;
			font-weight: 800;
		}

		.time_block {
			display: inline-block;
			width: 27px;
			text-align: center;
		}
	}

	.promotion_card__remaining {
		margin-top: 3px;
		font-family: $font_text;
		font-size: 10px;
		font-weight: 600;
		color: $text_color !important;
		line-height: 12.19px;
	}
}