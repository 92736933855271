@import "../../globalStyles/variables";

.rc-slider {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 1.8rem;
	border-radius: 1rem;
	touch-action: none;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
	position: absolute;
	width: 100%;
	height: .6rem;
	background-color: $bg_color;
	border-radius: 1rem;
}

.rc-slider-track {
	position: absolute;
	height: .6rem;
	background-color: $primary_color;
	border-radius: 1rem;
}

.rc-slider-handle {
	position: absolute;
	width: 2.6rem;
	height: 2.6rem;
	border-radius: 50%;
	cursor: pointer;
	cursor: -webkit-grab;
	cursor: grab;
	touch-action: pan-x;

	background-color: $text_color;
	border: .2rem solid $bg_color;

	transition: box-shadow .3s ease;
	will-change: box-shadow;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
	box-shadow: $black 0 0 .5rem;
}

.rc-slider-handle:focus {
	outline: none;
	box-shadow: none;
}

.rc-slider-handle:active {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

.rc-slider-mark {
	position: absolute;
	top: 1.8rem;
	left: 0;
	width: 100%;
	font-size: 1.2rem;
}

.rc-slider-mark-text {
	position: absolute;
	display: inline-block;
	color: #999;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
}

.rc-slider-mark-text-active {
	color: #666;
}