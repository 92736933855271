.telegram-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0088cc;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 150;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    animation: pulse 1.5s infinite;
}

.telegram-button:hover {
    background-color: #006699;
}

.telegram-button svg {
    color: white;
    width: 45px;
    height: 45px;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 136, 204, 0.7);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(0, 136, 204, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 136, 204, 0);
    }
}
