@import "../../../../globalStyles/variables";

.order_info_item {
	display: flex;
	align-items: center;
	gap: 2rem;

	padding: 1rem;
	font-size: 1.4rem;

	&:not(:last-child) {
		border-bottom: 1px solid rgba($black, .8);
	}
}

.order_info_item__key {
	font-weight: 700;
	width: 14rem;
}