.picture {
	display: block;
	width: 100%;
	height: 100%;
}

.image {
	display: block;
	width: 100%;
	height: 100%;

	&.image--contain_mod {
		object-fit: contain;
	}

	&:not(.image--contain_mod) {
		object-fit: cover;
	}
}