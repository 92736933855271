@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/typographyMixins";

.images_descriptions {
	position: relative;
	width: 100vw;
	min-height: 20rem;
	margin-left: calc(var(--gl-indent) * -1);
}

.images_descriptions__row {
	display: flex;
	align-items: stretch;
}

.images_descriptions__column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&.images_descriptions__column--full_mod {
		width: 100%;
	}

	&:not(.images_descriptions__column--full_mod) {
		width: 50%;
	}
}

.images_descriptions__image {
	display: block;
	width: 100%;
	height: 100%;

	img {
		object-position: top;
	}
}

.images_descriptions__content {
	padding: 4rem var(--gl-indent);
	text-align: center;

	max-width: 90rem;
	margin-left: auto;
	margin-right: auto;
}

.images_descriptions__title {
	@include h3;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.images_descriptions__text {
	line-height: 1.5;

	@include bp($point_2, min) {
		font-size: 1.6rem;
	}

	@include bp($point_3) {
		font-size: 1.4rem;
	}
}