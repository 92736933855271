@import "../../globalStyles/variables";

.swiper_arrow {
	padding: 1rem 0;
	font-size: 4rem;
	line-height: 0;

	background-color: rgba($white, .15);
	color: $primary_color;

	cursor: pointer;
	transition: opacity .3s ease, background .3s ease;

	&:not(.swiper_arrow--loading_state).swiper-button-disabled {
		opacity: 0;
	}

	&.swiper_arrow--loading_state,
	.swiper-button-disabled {
		pointer-events: none;
	}

	&.swiper_arrow--loading_state {
		svg {
			opacity: 0;
		}
	}

	svg {
		transition: opacity 0.3s ease 0s;
	}

	@media (any-hover: hover) {
		&:hover {
			background-color: rgba($white, .3);
		}
	}
}

.swiper_arrow__loader {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	.loader {
		background-color: transparent;
	}
}