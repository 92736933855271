@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.popup {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;

	display: flex;
	align-content: stretch;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	padding: 2rem var(--gl-indent);

	overflow-x: hidden;
	overflow-y: auto;

	background-color: rgba($black, .9);

	&.popup--open_state {
		animation: fadeIn 0.3s ease-in-out;
	}

	@include redesign_scrollbar;
}

.popup__content {
	position: relative;

	width: 100%;
	margin: auto;
	padding: 2rem var(--gl-indent);

	background-color: $bg_color;

	border-radius: .4rem;

	.popup--xxs_mod & {
		max-width: 47rem;
	}

	.popup--xs_mod & {
		max-width: 60rem;
	}

	.popup--base_mod & {
		max-width: 80rem;
	}

	.popup--lg_mod & {
		max-width: 120rem;
	}
}

.popup__close {
	position: absolute;
	top: -.6rem;
	right: -.6rem;

	font-size: 2.4rem;
	color: $text_color;

	transition: color 0.3s ease 0s;

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}