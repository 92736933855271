@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";

.feedback_form {
	padding: 4rem var(--gl-indent);

	h2 {
		@include h3;

		text-align: center;
	}
}