@import "../../globalStyles/mixins";
@import "../../globalStyles/typographyMixins";

.hero_slide {
	position: relative;

	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
		}
	}

	@include bp($point_2, min) {
		min-height: 31.25vw;
	}

	@include bp($point_3) {
		min-height: 57.14vw;
	}

	@include bp($point_4, min) {
		padding: 4rem max(8vw, 8rem);
	}

	@include bp($point_4 - 1) {
		padding: 4rem var(--gl-indent);
	}
}

.hero_slide__image {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.hero_slide__title {
	text-transform: uppercase;
	color: $white;

	@include bp($point_2, min) {
		max-width: 46rem;
	}

	@include bp($point_3, $point_4) {
		max-width: 28rem;
	}

	@include bp($point_4, min) {
		@include h2;
	}

	@include bp($point_4 - 1) {
		font-size: 1.6rem;
		max-width: 18rem;
	}
}