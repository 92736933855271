@import "../../globalStyles/typographyMixins";
@import "../../globalStyles/buttonsMixins";


.confirm_popup {
	padding: 1rem 0;
}

.confirm_popup__title {
	text-align: center;
	font-size: 2rem;

	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}

.confirm_popup__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2rem;
}

.confirm_popup__button {
	min-width: 16rem;
	@include btn_size_base;

	&.confirm_popup__button--confirm_mod {
		@include btn_style_secondary;
	}

	&.confirm_popup__button--decline_mod {
		@include btn_style_primary;
	}
}