@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/typographyMixins";
@import "../../globalStyles/buttonsMixins";

.product_card {
	display: flex;
	width: 100%;
	flex-direction: column;
	flex: 1 1 auto;

	border-radius: 1rem;
	overflow: hidden;


	box-shadow: rgba($black, 0.2) 0 .2rem 1rem 0, rgba($black, 0.1) 0 .2rem .4rem 0, rgba($white, 0.05) 0 0 0 .1rem inset;
	background-color: $bg_dedicated_color;
}

.product_card__block {
	position: relative;
}

.product_card__label_container {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 5;

	width: 100%;
	pointer-events: none;
	color: $black;

	display: flex;
	flex-direction: column;

	padding: 1rem;
}

.product_card__label {
	display: inline-block;
	margin-left: auto;
	padding: .2rem .8rem;

	border-radius: 1rem;
	overflow: hidden;

	color: $white;

	font-size: 1.2rem;
	font-weight: 700;

	&:not(:last-child) {
		margin-bottom: .6rem;
	}

	&.product_card__label--sale_mod {
		background-color: $blue;
	}

	&.product_card__label--promo_mod {
		background-color: $orange;
	}

	&.product_card__label--pair_mod {
		background-color: $purple;
	}
}

.product_card__link {
	display: block;
	width: 100%;

	transition: color .3s ease;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
			color: $primary_color;
		}
	}
}

.product_card__title {
	strong {
		font-weight: 700;
	}

	@include h5;
}

.product_card__rating {
	display: flex;
	align-items: center;

	span {
		display: inline-block;
		margin-left: 0.4rem;
		font-size: 1rem;
	}

	&:not(:last-child) {
		margin-bottom: .8rem;
	}
}

.product_card__wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.product_card_image {
	padding: 1rem;
	background-color: $white;
	overflow: hidden;
	border-radius: 1rem 1rem 0 0;
}

.product_card_image__wrap {
	position: relative;
	display: block;
	width: 100%;
	padding-bottom: 100%;


	@media (any-hover: hover) {
		img {

			transition: transform .3s ease;

			.product_card__link:hover & {
				transform: scale(1.1);
			}
		}
	}
}

.product_card__like {
	position: absolute;
	z-index: 10;
	bottom: .4rem;
	right: .4rem;

	color: $primary_color;

	@media (any-hover: hover) {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease 0s;

		.product_card:hover & {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.product_card__delete_btn {
	position: absolute;
	z-index: 10;
	top: .4rem;
	left: .4rem;

	width: 2.6rem;
	height: 2.6rem;
	border-radius: 50%;

	font-size: 2rem;
	color: $primary_color;
	border: 1px solid $primary_color;

	transition: background-color .3s ease, color .3s ease;

	@media (any-hover: hover) {
		&:hover {
			background-color: $primary_color;
			color: $white;
		}
	}
}

.product_card__container {
	display: flex;
	flex-direction: column;

	flex: 1 1 auto;

	padding: var(--gl-indent);

	&>.product_card__link {
		flex: 1 1 auto;

		&:not(:last-child) {
			margin-bottom: .8rem;
		}
	}
}

.product_card_image__picture {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.product_card__price {
	&:not(:last-child) {
		margin-right: 2rem;
	}
}

.product_card__button {
	margin-left: auto;

	@include btn_size_square;

	&:not(.product_card__button--active_state, .product_card__button--not_available_state) {
		@include btn_style_buy;
	}
}