@import "../../globalStyles/variables";

.mega_item__wrap {
	display: flex;
	justify-content: center;
}

.mega_item__button {
	display: flex;
	align-items: center;

	font-weight: 700;
	color: $text_color;

	transition: color .3s ease;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
			color: $primary_color;
		}
	}
}

.mega_item__link {
	transition: color .3s ease;

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
			text-decoration: none;
		}
	}
}

.mega_item__icon {
	display: block;

	font-size: 2.4rem;
	color: inherit;

	&:not(:last-child) {
		margin-right: .6rem;
	}
}

.mega_item__arrow {
	display: block;

	font-size: 2.4rem;
	color: inherit;

	&:not(:first-child) {
		margin-left: .4rem;
	}

	transform: rotate(-90deg);
}

.mega_item__text {
	display: block;

	font-size: 1.6rem;
	font-weight: 700;
	text-transform: uppercase;
	color: inherit;
}