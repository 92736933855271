@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";

.news_card {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	box-shadow: rgba($black, 0.2) 0 .2rem 1rem 0, rgba($black, 0.1) 0 .2rem .4rem 0, rgba($white, 0.05) 0 0 0 .1rem inset;
	border-radius: .6rem;
	overflow: hidden;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
		}
	}
}

.news_card__wrap {
	position: relative;
	padding-bottom: 48%;
	overflow: hidden;
}

.news_card__image {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	transition: transform 0.3s ease 0s;
	will-change: transform;

	@media (any-hover: hover) {
		.news_card:hover & {
			transform: scale(1.1);
		}
	}
}

.news_card__content {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;

	padding: 2rem;
	background-color: $bg_dedicated_color;
}

.news_card__title {
	flex: 1 1 auto;
	transition: color 0.3s ease 0s;

	&:not(:last-child) {
		margin-bottom: 1.4rem;
	}

	@include h5;
	@include text_overflow(3);

	@media (any-hover: hover) {
		.news_card:hover & {
			color: $primary_color
		}
	}
}

.news_card__row {
	display: flex;
	align-items: flex-end;
}

.news_card__date {
	font-size: 1.2rem;
	color: $text_color;
	opacity: 0.8;

	&:not(:last-child) {
		margin-right: 2rem;
	}
}

.news_card__arrow {
	font-size: 2rem;
	margin-left: auto;

	transition: color 0.3s ease 0s;

	@media (any-hover: hover) {
		.news_card:hover & {
			color: $primary_color
		}
	}
}