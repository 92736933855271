.empty-state-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 2rem;
  }
  
  .empty-state-image {
	width: 150px;
	height: 150px;
	object-fit: cover;
	margin-bottom: 1rem;
  }
  
  .empty-state-title {
	font-size: 1.5rem; 
	font-weight: bold;
	color: black; 
	margin-bottom: 0.5rem;
  }
  
  .empty-state-description {
	max-width: 650px;
	font-size: 1.4rem; 
	color: gray; 
  }