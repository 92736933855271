@import "../../globalStyles/variables";

.section {
	position: relative;

	width: 100%;

	font-size: 1rem;
}

.section_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;
}