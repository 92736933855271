@import "../../globalStyles/formMixins";
@import "../../globalStyles/variables";

.search_form {
	display: flex;
	width: 100%;

	@include bp($point_2, min) {
		max-width: 62rem;
		margin-left: 2.6rem;
	}

	@include bp($point_3) {
		max-width: 80rem;
		margin: 0 auto;
	}
}

.search_form__input {
	@include search_input;
	background-color: $bg_color;
	border-radius: .3rem 0 0 .3rem;
}

.search_form__button {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 4rem;
	height: 4rem;
	flex-shrink: 0;
	margin-left: -.2rem;

	border-radius: 0 .3rem .3rem 0;
	background-color: $primary_color;

	@media (any-hover: hover) {
		&:hover {
			.search_form__icon {
				transform: scale(1.2);
			}
		}
	}
}

.search_form__icon {
	display: block;
	flex-shrink: 0;

	font-size: 1.6rem;
	color: $white;

	transition: transform .3s ease 0s;
	will-change: transform;
}