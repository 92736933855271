@import "./mixins";
@import "./variables";

@mixin h1 {
	font-family: $font_heading;

	@include bp($point_2, min) {
		font-size: 5.4rem;
	}

	@include bp($point_3) {
		font-size: 3.4rem;
	}
}

@mixin h2 {
	font-family: $font_heading;


	@include bp($point_2, min) {
		font-size: 3.8rem;
	}

	@include bp($point_3) {
		font-size: 2.2rem;
	}
}

@mixin h3 {
	font-family: $font_heading;


	@include bp($point_2, min) {
		font-size: 3.2rem;
	}

	@include bp($point_3) {
		font-size: 2.2rem;
	}
}

@mixin h4 {
	font-family: $font_heading;

	@include bp($point_2, min) {
		font-size: 2.6rem;
	}

	@include bp($point_3) {
		font-size: 1.8rem;
	}
}

@mixin h5 {
	font-family: $font_heading;
	font-size: 1.4rem;
}

@mixin h6 {
	font-family: $font_heading;

	@include bp($point_2, min) {
		font-size: 2rem;
	}

	@include bp($point_3) {
		font-size: 1.6rem;
	}
}

@mixin text {
	@include bp($point_2, min) {
		font-size: 1.6rem;
	}

	@include bp($point_3) {
		font-size: 1.4rem;
	}
}