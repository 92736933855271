@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.hero_slider {
	background: $black;
}

.hero_slider__body {

	.swiper-pagination {
		position: absolute;
		z-index: 3;
		bottom: 1rem;
		left: 50%;
		transform: translateX(-50%);
	}
}

.hero_slider__arrow {
	position: absolute;
	top: 50%;
	z-index: 3;

	$indent: 2rem;

	&.hero_slider__arrow--prev_mod {
		transform: translateY(-50%) rotate(-180deg);
		left: $indent;
	}

	&.hero_slider__arrow--next_mod {
		transform: translateY(-50%);
		right: $indent;
	}

	@include bp($point_4 - 1) {
		display: none;
	}
}