@import "../../../../globalStyles/variables";
@import "../../../../globalStyles/mixins";
@import "../../../../globalStyles/typographyMixins";

.promotion_item {
	background-color: $bg_dedicated_color;
	padding: 5px 0;
	margin: 10px 0 0;
}

.promotion_item__button {
	display: flex;
	width: 100%;
	align-items: center;
	gap: 2rem;
	font-size: 1.4rem;
	color: $text_color;
	cursor: pointer;
}

.promotion_item__main {
	flex: 1 1 auto;
	display: flex;
	gap: 1rem;
	align-items: center;
	padding: 10px 10px 10px 0;
}

.promotion_item__title {
	font-size: 1.6rem;
	font-weight: 700;
	text-align: left;
	color: $primary_color;
}

.promotion_item__arrow {
	flex-shrink: 0;
	font-size: 1.8rem;
	color: $primary_color;
	transition: transform 0.3s ease 0s, color .3s ease;
	will-change: transform;

	.promotion_item--active_state & {
		transform: rotateX(-180deg);

	}
}

.promotion_item__content {
	overflow: hidden;
	height: 0;
}

.promotion_item__footer {
	background-color: $bg_dedicated_color;
	padding: 5px 10px 5px 0;
	font-size: 1.4rem;
	line-height: 1.5;
}