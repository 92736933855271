@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/buttonsMixins";

#buy_button {
	z-index: 10;
	font-size: 12px;
}

.buy_button {
	position: relative;

	a {
		@media (any-hover: hover) {
			&:hover {
				text-decoration: none;

			}
		}
	}

	&.buy_button--loading_state {
		@include btn_style_disabled_secondary;
	}

	&.buy_button--active_state {
		@include btn_style_disabled_secondary;
	}

	&.buy_button--not_available_state {
		@include btn_style_not_availabel;
	}

	&.buy_button--notify_state {
		@include btn_style_notify;
	}

	&.buy_button--disabled_state {
		pointer-events: none !important;
	}

	.font_icon {
		display: block;
	}
}

.buy_button__tooltip {
	position: absolute;
}


.buy_button__icon {
	display: flex;
	align-items: center;

	span {
		margin-left: .4rem;
	}
}