@import "../../globalStyles/variables";
@import "../../globalStyles/buttonsMixins";

.orders {}

.orders__body {
	background-color: $bg_dedicated_color;
	border-radius: .4rem;
	overflow: hidden;
	box-shadow: rgba($black, .2) 0 .2rem 1rem 0, rgba($black, .1) 0 .2rem .4rem 0, rgba($white, .05) 0 0 0 .1rem inset;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.orders__item {
	&:not(:last-child) {
		border-bottom: 1px solid $grey;
	}
}

.orders__message {
	padding: 2rem;
	font-size: 1.6rem;
	text-align: center;

	p:not(:last-child) {
		margin-bottom: .8rem;
	}
}

.orders__nav {
	display: flex;
	align-items: center;

	background-color: $bg_dedicated_color;
	border-radius: .4rem;
	box-shadow: rgba($black, .2) 0 .2rem 1rem 0, rgba($black, .1) 0 .2rem .4rem 0, rgba($white, .05) 0 0 0 .1rem inset;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.orders__button {
	width: 50%;
	color: $text_color;
	transition: color 0.3s ease 0s;

	@include btn_size_base;

	&.orders__button--current_state {
		background-color: rgba($primary_color, .6);
		pointer-events: none;
	}

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
		}
	}
}