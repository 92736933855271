@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.mega_catalog {
	position: fixed;
	top: 0;
	left: 0;
	overflow-x: hidden;
	z-index: 10;

	display: block;
	width: 100vw;
	height: 100%;
	max-height: 100vh;
	padding: calc(var(--height-header) + 6rem) 0 4rem;

	background-color: $bg_menu_color;

	transition: opacity .3s, transform .3s, max-height .3s;

	&:not(.mega_catalog--current_mod) {
		overflow-y: hidden;
	}

	&.mega_catalog--current_mod {
		overflow-y: auto;
	}

	&:not(.mega_catalog--open_state) {
		transform: translateX(100%);
		pointer-events: none;
		opacity: 0;
	}

	.mega_catalog--open_state {
		transform: translateX(0);
		pointer-events: initial;
		opacity: 1;
	}

	@include redesign_scrollbar;
}

.menu_catalog__back {
	display: flex;
	align-items: center;

	padding: 2rem .5rem;

	font-size: 1.4rem;
	color: $text_color;
	font-weight: 700;
	transition: color .3s ease;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
		}
	}
}

.menu_catalog__arrow {
	display: block;
	font-size: 1.6rem;
	transform: rotate(90deg);
	color: inherit;

	&:not(:last-child) {
		margin-right: .4rem;
	}
}

.menu_catalog__body {
	padding: 0 var(--gl-indent);
}

.mega_catalog__list {
	text-align: center;
}

.mega_catalog__item {
	display: block;
	width: 100%;

	padding: 1.8rem var(--gl-indent);
}