@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.catalog_trigger {
	min-height: 4rem;
	display: flex;
	align-items: center;
}

.catalog_trigger__wrap {
	position: relative;
	z-index: 2;

	display: flex;
	align-items: center;

	font-weight: 700;
	color: $text_color;

	transition: color .3s ease;

	// for click area
	&::after {
		content: '';

		position: absolute;
		bottom: 0;
		left: 0;
		transform: translateY(100%);

		width: 100%;
		height: 4rem;

		opacity: 0;
		pointer-events: none;

		.catalog_trigger--open_state & {
			pointer-events: auto;
		}
	}

	.catalog_trigger--open_state & {
		color: $primary_color;
	}

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
		}
	}
}

.catalog_trigger__icon {
	display: block;

	font-size: 2.4rem;
	color: inherit;

	&:not(:last-child) {
		margin-right: .6rem;
	}
}

.catalog_trigger__text {
	display: block;

	font-size: 1.6rem;
	font-weight: 700;
	text-transform: uppercase;
	color: inherit;
}

.catalog_trigger__arrow {
	display: block;

	font-size: 2.4rem;
	color: inherit;

	transition: transform .3s ease;
	will-change: transform;

	&:not(:first-child) {
		margin-left: .4rem;
	}

	.catalog_trigger--open_state & {
		transform: rotateX(-180deg);
	}
}

.catalog_trigger__container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;

	padding-top: var(--height-header);
	height: auto;

	transition: opacity .3s ease;

	&::before {
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;

		width: 100%;
		height: 100vh;

		background: rgba($black, .5);
		transition: opacity .3s ease;
		pointer-events: none;
		content: '';
	}

	&:not(.catalog_trigger--open_state &) {
		pointer-events: none;
		opacity: 0;

		&::before {
			opacity: 0;
		}
	}

	.catalog_trigger--open_state & {
		pointer-events: auto;
		opacity: 1;

		animation: fadeIn 0.3s ease-in-out;

		&::before {
			opacity: 1;
		}
	}
}

.catalog_trigger__body {
	position: relative;

	width: 100%;
	height: 100%;
	border-top: .1rem solid $primary_color;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}