@import "../../globalStyles/variables";

.review_item {
	padding: 2rem;

	background-color: $bg_dedicated_color;
	border-radius: .4rem;
}

.review_item__head {
	display: flex;

	&:not(:last-child) {
		margin-bottom: 1.4rem;
	}
}

.review_item__wrap {
	flex: 1 1 auto;

	&:not(:last-child) {
		margin-right: 2rem;
	}
}

.review_item__name {
	font-size: 1.8rem;
	font-weight: 700;

	&:not(:last-child) {
		margin-bottom: .8rem;
	}
}

.review_item__rating {
	margin-left: auto;
}

.review_item__date {
	font-size: 1.4rem;
	opacity: .8;
}

.review_item__comment {
	font-size: 1.6rem;
}