@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";

.text_section {
	padding: 3rem var(--gl-indent);

	h1 {
		@include h2;

		display: inline-block;

		padding-bottom: 0.4rem;
		border-bottom: .2rem solid $primary_color;


		&:not(:last-child) {
			margin-bottom: 2.6rem;
		}
	}

	h2 {
		@include h2;

		&:not(:last-child) {
			margin-bottom: 1.8rem;
		}
	}

	h3,
	h4,
	h5 {
		font-family: $font_heading;
		font-size: 1.6rem;

		&:not(:last-child) {
			margin-bottom: 1.4rem;
		}
	}

	a {
		color: $primary_color;

		transition: opacity 0.3s ease 0s;

		@media (any-hover: hover) {
			&:hover {
				text-decoration: none;
				opacity: 0.8;
			}
		}
	}

	strong {
		font-weight: 700;
	}

	ul,
	ol {
		padding-left: 2rem;

		li:before {
			display: inline-block;
		}
	}

	ul {
		li::before {
			content: '';
			margin-bottom: .2rem;
			margin-right: .8rem;

			width: 0.4rem;
			height: 0.4rem;
			border-radius: 50%;

			background-color: $text_color;
		}
	}

	ol {
		counter-reset: numericList;

		li::before {
			counter-increment: numericList;
			content: counter(numericList) '.';

			margin-right: .8rem;
		}
	}

	ul,
	ol,
	p {
		font-size: 1.4rem;

		&:not(:last-child) {
			margin-bottom: 2.4rem;
		}
	}
}