@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";
@import "../../globalStyles/mixins";

.news_section {
	padding: 3rem var(--gl-indent);
}

.news_section__title {
	@include h2;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.news_section__list {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	margin: 0 -2rem -2rem 0;
}

.news_section__item {
	padding: 0 2rem 2rem 0;

	@include bp($point_2, min) {
		width: 25%;
	}

	@include bp($point_3, $point_5) {
		width: 50%;
	}

	@include bp($point_5 - 1) {
		width: 100%;
	}
}