@import "../../globalStyles/variables";
@import "../../globalStyles/buttonsMixins";

.rate_popup__form {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 0 2rem;
}

.rate_popup__title {
	font-size: 2rem;
	font-weight: 700;

	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}

.rate_popup__rating {
	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}

.rate_popup__button {
	@include btn_size_base;
	@include btn_style_primary;
}