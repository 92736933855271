@import "../../globalStyles/variables";
@import "../../globalStyles/buttonsMixins";

.unregistered_message {
	padding: 4rem 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.unregistered_message__title {
	font-size: 2rem;
	font-weight: 700;

	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}

.unregistered_message__button {
	@include btn_size_base;
	@include btn_style_secondary;
}