@import "../../globalStyles/variables";

.rating {
	position: relative;

	&.rating--base_mod {
		width: 8.6rem;
		height: 1.4rem;
	}

	&.rating--small_mod {
		width: 6.6rem;
		height: 1rem;
	}
}

.rating__stars {
	position: absolute;
	top: 0;
	left: 0;

	display: flex;
	align-items: center;

	&:not(.rating__stars--fill_mod) {
		z-index: 1;
		color: $primary_color;
	}

	&.rating__stars--fill_mod {
		z-index: 2;
		color: $primary_color;
	}
}

.rating__star {
	overflow: hidden;

	&:not(:last-child) {
		margin-right: 0.4rem;
	}

	.rating--base_mod & {
		font-size: 1.4rem;
	}

	.rating--small_mod & {
		font-size: 1rem;
	}

	path {
		transform: scale(1.21, 1.21) translate(-8.4%, -4.6%);
	}
}