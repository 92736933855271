@import "../../globalStyles/variables";

.price {
	position: relative;

	display: block;
	color: $text_color;
	text-align: left;

	// &.price--old_mod,
	&.price--single_mod {
		padding-top: 1.4rem;
	}
}

.price__wrapper {
	display: flex;
	gap: 4px;
}

.price__val {
	&.price__val--single_mod {
		position: absolute;
		top: 0;
		left: 0;

		font-size: 1rem;
		opacity: 0.8;
	}

	&.price__val--old_mod {
		text-decoration: line-through;
		font-size: 1.4rem;
		color: $placeholder_color;
		.price--xs_size & {
			font-size: 1rem;
		}
	}

	&.price__val--current_mod {
		font-family: $font_text;
		color: $primary_color;
		font-weight: 600;
		// font-size: 1.8rem;

		.price--base_size & {
			font-size: 2rem;
		}

		.price--xs_size & {
			font-size: 1.6rem;
		}
	}

	&.price__val--promo_mod {
		// color: $primary_color;
		// font-weight: 600;
		.price--base_size & {
			font-size: 2rem;
		}

		.price--xs_size & {
			font-size: 1.6rem;
		}
	}

	&.price__val--difference_mod {
		color: $primary_color;
		font-weight: 700;

		.price--xs_size & {
			font-size: 1rem;
		}
	}
}