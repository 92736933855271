@import "../../globalStyles/variables";
@import "../../globalStyles/buttonsMixins";

.app_buttons {}

.app_buttons__list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	margin: 0 -2rem -2rem 0;
}

.app_buttons__item {
	padding: 0 2rem 2rem 0;
}

.app_buttons__link {
	@include btn_style_app;
	@include btn_size_normal;
}

.app_buttons__logo {
	display: flex;
	align-items: center;
	width: 10rem;
	height: 3rem;

	svg {
		display: block;
		width: 100%;
		height: 100%;
	}
}