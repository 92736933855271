@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.orders_item {}

.orders_item__row {
	position: relative;

	display: flex;
	width: 100%;
	align-items: center;

	gap: 2rem;
	padding: 2rem 2rem 2rem 4rem;

	font-size: 1.4rem;
	color: $text_color;

	cursor: pointer;

	@include bp($point_4 - 1) {
		flex-direction: column;
	}
}

.orders_item__decor {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 2rem;

	flex-shrink: 0;
	min-height: 3.6rem;
	height: calc(100% - 4rem);
	width: .6rem;
	border-radius: .8rem;

	.orders_item--pending_mod & {
		background-color: $orange;
	}

	.orders_item--closed_mod & {
		background-color: $primary_color;
	}
}

.orders_item__id {
	font-weight: 700;

	@include bp($point_4, min) {
		width: 10rem;
	}
}

.orders_item__date {
	opacity: .8;

	@include bp($point_4, min) {
		width: 10rem;
	}
}

.orders_item__status {
	.orders_item--pending_mod & {
		color: $orange;
	}

	.orders_item--closed_mod & {
		color: $primary_color;
	}

	@include bp($point_4, min) {
		width: 12rem;
	}
}

.orders_item__price {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;

	font-weight: 700;

	@include bp($point_4, min) {
		width: 16rem;
	}
}

.orders_item__wrap {
	display: flex;
	gap: 2rem;

	@include bp($point_4 - 1) {
		justify-content: space-between;
		width: 100%;
	}
}

.orders_item__icon {
	font-size: 1.8rem;
	margin-left: auto;

	transition: transform 0.3s ease 0s;
	will-change: transform;

	.orders_item--active_state & {
		transform: rotateX(-180deg);
	}
}

.orders_item__body {
	padding: 0 2rem;
	border-top: 1px solid $grey;
	overflow: hidden;
	height: 0;
}

.orders_item__product {
	padding: 2rem 0;

	&:not(:last-child) {
		border-bottom: 1px solid $text_color;
	}
}

.orders_item__label {
	@include bp($point_4, min) {
		display: none;
	}
}

.orders_item__info {
	margin: 2rem 0;

	border-radius: .8rem;
	background-color: $bg_color;
}