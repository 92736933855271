@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";
@import "../../globalStyles/buttonsMixins";

.not_found {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	padding: 3rem var(--gl-indent);
	min-height: calc(100vh - var(--height-header));
}

.not_found__title {
	@include h1;

	line-height: 1.1;

	span {
		display: block;
		letter-spacing: .2em;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.not_found__text {
	font-size: 1.8rem;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.not_found__link {
	@include btn_size_big;
	@include btn_style_primary;
}