@import "../../globalStyles/variables";

.order_product {
	display: flex;
	gap: 2rem;
	align-items: center;

	font-size: 1.4rem;
	color: $text_color;

	transition: color 0.3s ease 0s;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
			color: $primary_color;
		}
	}

}

.order_product__image {
	position: relative;
	display: block;
	width: 6rem;
	height: 6rem;

	border-radius: .4rem;
	overflow: hidden;
}

.order_product__picture {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.order_product__name {
	width: calc(58% - 8rem);
}

.order_product__name__title{
	margin-bottom: 5px;
};

.order_product__amount {
	color: $text_color;
	width: calc(20% - 2rem);
}

.order_product__price {
	color: $text_color;
	width: calc(22% - 2rem);
}