@import "../../globalStyles/variables";
@import "../../globalStyles/buttonsMixins";

.auth_tabs {
	min-height: 30rem;
	display: flex;
	flex-direction: column;
}

.auth_tabs__nav {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}

	&.auth_tabs__nav--hidden_state {
		display: none;
	}
}

.auth_tabs__list {
	display: flex;
	justify-content: space-around;
	margin-right: -2rem;
}

.auth_tabs__item {

	&:not(.auth_tabs__item--hidden_mod) {
		padding-right: 2rem;
	}

	&.auth_tabs__item--hidden_mod {
		display: none;
	}
}

.auth_tabs__button {
	position: relative;

	color: $text_color;
	padding: .4rem .8rem;

	transition: color 0.3s ease 0s;

	&::before,
	&::after {
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: .2rem;

		content: '';
	}

	&::before {
		z-index: 1;
		background: $text_color;
	}

	&::after {
		z-index: 2;
		background: $primary_color;
		transform: scaleX(0);
		transition: transform 0.3s ease 0s;
	}

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
		}
	}

	.react-tabs__tab--selected & {
		pointer-events: none;

		color: $primary_color;

		&::after {
			transform: scaleX(1);
		}
	}
}

.auth_tabs__panels {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.auth_tabs__footer {
	justify-content: center;
	padding-top: 4rem;

	&:not(.auth_tabs__footer--hidden_state) {
		display: flex;
	}

	&.auth_tabs__footer--hidden_state {
		display: none;
	}
}

.auth_tabs__forgot {
	position: relative;

	font-size: 1.4rem;
	color: $text_color;
	text-align: center;

	transition: color 0.3s ease 0s;

	&::before,
	&::after {
		position: absolute;
		bottom: -0.2rem;
		left: 0;

		width: 100%;
		height: 0.1rem;

		content: '';
	}

	&::before {
		background-color: $text_color;
	}

	&::after {
		background-color: $primary_color;
		transform: scaleX(0);
		transition: transform 0.3s ease 0s;
		will-change: transform;
	}

	&.auth_tabs__forgot--active_state {
		color: $primary_color;

		&::after {
			transform: scaleX(1);
		}
	}

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
		}
	}
}