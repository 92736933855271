@import "../../globalStyles/variables";
@import "../../globalStyles/formMixins";

.quantity {
	display: flex;
	align-items: center;
	gap: .6rem;
};

.quantity__input {
	@include range_input;
	max-width: 8rem;
	text-align: center;
};

.quantity__btn {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 2.6rem;
	height: 2.6rem;

	font-size: 2.6rem;
	color: $text_color;

	transition: color 0.3s ease, opacity 0.3s ease;
	will-change: color;

	&.quantity__btn--disabled_mod {
		opacity: 0.6;
		pointer-events: none;
	}

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
		}
	}
}