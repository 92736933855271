@import "../../globalStyles/variables";

.notify_products {
	position: relative;
	min-height: 20rem;
}

.notify_products__body {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.notify_products__message {
	padding: 2rem;
	font-size: 1.6rem;
	text-align: center;

	p:not(:last-child) {
		margin-bottom: .8rem;
	}
}