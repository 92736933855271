@import "../../globalStyles/typographyMixins";
@import "../../globalStyles/variables";

.search_result {
	padding: 3rem var(--gl-indent);
	min-height: 60vh;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.search_result__head {
	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}

.search_result__placeholder {
	max-width: 60rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.search_result__title {
	@include h4;


	.search_result--no_result & {
		&:not(:last-child) {
			margin-bottom: 3rem;
		}
	}

	&:not(.search_result--no_result &) {
		margin-bottom: 2rem;
	}

}

.search_result__text {
	font-size: 1.6rem;
	line-height: 1.4;
	text-align: center;

	p:not(:last-child) {
		margin-bottom: 1rem;
	}
}